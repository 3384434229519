import { TableCell, Tooltip } from '@mui/material';
import { Cell, Identible, ImagableColumnDefinition, Linkable, Suffixable, Tooltipable } from '../types';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import NotInterestedIcon from '@mui/icons-material/NotInterested';

export interface IconCell extends Cell<string>, Identible, Tooltipable, Suffixable, Linkable {
  image: string;
}

function renderIconCell(cell: IconCell, columnDef: ImagableColumnDefinition) {
  function renderCellContent(cell: IconCell) {
    if (cell.href) {
      return (
        <a href={cell.href} target={cell.target ?? '_blank'} rel="noreferrer">
          {columnDef.imageMap[cell.image]}
        </a>
      );
    }
    return <span>{columnDef.imageMap[cell.image]}</span>;
  }

  return (
    <TableCell key={cell.id} align={IconColumnDefinition.align}>
      {cell.tooltip ? <Tooltip title={cell.tooltip}>{renderCellContent(cell)}</Tooltip> : renderCellContent(cell)}
    </TableCell>
  );
}

function stringComparator<RowType>(sortBy: keyof RowType) {
  return (a: RowType, b: RowType) => {
    const aObj = a[sortBy] as unknown as { value: string };
    const bObj = b[sortBy] as unknown as { value: string };
    if (aObj.value < bObj.value) {
      return -1;
    }
    if (aObj.value > bObj.value) {
      return 1;
    }
    return 0;
  };
}

function iconFilterFn(cell: Cell<unknown>) {
  return (filterValue: string): boolean => {
    const iconCell = cell as IconCell;
    const searchString = iconCell.suffix ? `${iconCell.value}${iconCell.suffix}` : iconCell.value;
    if (!searchString) {
      return false;
    }
    return searchString.toLowerCase().includes(filterValue.toLowerCase());
  };
}

export const IconColumnDefinition: ImagableColumnDefinition = {
  sortable: true,
  filterable: true,
  align: 'center',
  width: '1px',
  render: (rows, columnDefinition) => renderIconCell(rows, columnDefinition as ImagableColumnDefinition),
  comparator: stringComparator,
  filterFn: iconFilterFn,
  imageMap: {
    cert: <WorkspacePremiumIcon />,
    noCert: <NotInterestedIcon />
  }
};
