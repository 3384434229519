import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AwsResourceType } from '../../services/useAwsResources';
import { Stack } from '@mui/system';
import ReactJson from 'react-json-view';
import React from 'react';
import { AwsCloudFrontConfigurationRecord } from '.';

interface Props {
  resource: AwsResourceType;
}

const AwsCloudFrontDetails: React.FC<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const descriptionElementRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const configuration = props.resource.configuration as AwsCloudFrontConfigurationRecord;

  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={10}>
        <b>webACLId:</b> {configuration.distributionConfig.webACLId ? configuration.distributionConfig.webACLId : 'N/A'}
      </Grid>
      <Grid item xs={2}>
        <Button variant="outlined" size="small" onClick={handleOpen}>
          Configuration details
        </Button>
        <Dialog open={open} onClose={handleClose} scroll="paper" fullWidth={true} maxWidth={false}>
          <DialogTitle id="scroll-dialog-title">
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              Configuration details
              <IconButton onClick={handleClose}>
                <CloseIcon></CloseIcon>
              </IconButton>
            </Stack>
          </DialogTitle>
          <DialogContent dividers={true}>
            <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
              <ReactJson src={props.resource} collapsed={false}></ReactJson>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </Grid>
      <Grid item xs={12}>
        <b>Comment:</b> {configuration.distributionConfig.comment ? configuration.distributionConfig.comment : 'N/A'}
      </Grid>
      <Grid item xs={12}>
        <b>Viewer certificate details:</b>
      </Grid>
      <Grid item xs={12}>
        <b>Arn: </b>
        {configuration.distributionConfig.viewerCertificate.certificate
          ? configuration.distributionConfig.viewerCertificate.certificate
          : 'N/A'}
      </Grid>
      <Grid item xs={3}>
        <b>SSL supported: </b>
        {configuration.distributionConfig.viewerCertificate.sslSupportMethod
          ? configuration.distributionConfig.viewerCertificate.sslSupportMethod
          : 'N/A'}
      </Grid>
      <Grid item xs={3}>
        <b>Minimum Protocol Version: </b>
        {configuration.distributionConfig.viewerCertificate.minimumProtocolVersion
          ? configuration.distributionConfig.viewerCertificate.minimumProtocolVersion
          : 'N/A'}
      </Grid>
      <Grid item xs={3}>
        <b>CloudFront Default Certificate: </b>
        {configuration.distributionConfig.viewerCertificate.cloudFrontDefaultCertificate ? 'Yes' : 'No'}
      </Grid>
      <Grid item xs={3}>
        <b>Certificate Source: </b>
        {configuration.distributionConfig.viewerCertificate.certificateSource
          ? configuration.distributionConfig.viewerCertificate.certificateSource
          : 'N/A'}
      </Grid>
    </Grid>
  );
};

export default AwsCloudFrontDetails;
